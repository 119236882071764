<script lang="ts">
	import { classes } from '$lib/utils';
	export let additionalClass = '';
</script>

<svg
	class={classes('w-6 h-6', additionalClass)}
	height="100%"
	width="100%"
	version="1.1"
	viewBox="0 0 48 48"
	fill="currentColor"
	><use xlink:href="#ytp-id-50" />
	<g clip-path="url(#clip0_3213_20962)">
		<g clip-path="url(#clip1_3213_20962)">
			<path
				d="M46.6286 46.6286H37.2581V31.9252C37.2581 28.4195 37.195 23.9052 32.3836 23.9052C27.5722 23.9052 26.7557 27.7244 26.7557 31.6676V46.6274H17.3852V16.3992H26.3808V20.5307H26.5022C28.3365 17.3896 31.7479 15.5134 35.3789 15.6483C44.8768 15.6483 46.6286 21.9063 46.6286 30.0466V46.6286ZM6.81126 12.2665C3.80747 12.2665 1.37264 9.82889 1.37143 6.82017C1.37022 3.81146 3.80504 1.37264 6.80884 1.37143C9.81264 1.37143 12.2475 3.80903 12.2487 6.81774C12.2487 9.82768 9.81506 12.2653 6.81126 12.2665ZM11.4965 46.6274H2.11631V16.3992H11.4977L11.4965 46.6274Z"
				stroke="#8F00FF"
				stroke-miterlimit="10"
			/>
		</g>
	</g>
	<defs>
		<clipPath id="clip0_3213_20962">
			<rect width="48" height="48" fill="white" />
		</clipPath>
		<clipPath id="clip1_3213_20962">
			<rect width="48" height="48" fill="white" />
		</clipPath>
	</defs>
</svg>
